(function($){

	var PB = function() {
		
		var s = this;
		

	}

	$(document).ready(function() {
		new PB();
	})

})(jQuery);